
import pageint from "@/lib/utils/pageint";
import { useRouter } from "next/router";
import React from "react";
import ItemProfileStateContainer from "@/components/profile/item/item-profile-state-container";
import ItemProfilePlaceholder from "@/components/profile/item/item-profile-placeholder";

export default function ProfileArray({

   itemdata
  ,isLoading = false
  ,isError = false
  ,sum_items = 0
  ,userdata
  ,shrink=false
  ,ownerdata    //bol_admin_help
  ,nodata
  ,navtype
  ,helptype 
}) {
  const router = useRouter();
  const pagenumber = pageint(router.query.page);

  //const user_name = router.query.user_name?.toString();


  if (isLoading ) {
    return (
      <ul className={`divide-y w-full
        divide-gray-700
      `}>
       
        <ItemProfilePlaceholder 
            display={sum_items}   
            userdata={userdata}
            shrink={shrink}
            owner_name={ownerdata?.user_name}
            placeholder={true}
            navtype={navtype}
            helptype={helptype}
        />
      </ul>
    );
  }

  if (isError) {
    return <div className="px-4">Something went wrong...</div>;
  }

  // console.log("items",sum_items,channel_items)

  if (
    !isLoading &&
    !isError &&
    (!itemdata || (itemdata["data"] && itemdata["data"] == 0))
  ) {
    return (
      <>
        
       
          <div className="w-full px-4 py-5 mt-3 text-lg text-center text-white font-bold">
          {nodata}
          </div>
        
    </>);
  } else {

    //console.log("items.data",items.data)

    return (
    <>
      <ul className={` divide-y w-full
        divide-gray-700
      `}>
        
        {itemdata?.data?.map((e,index) => (
          <ItemProfileStateContainer
            key={index}
            itemdata={e}
            userdata={userdata}
            shrink={shrink}
            ownerdata={ownerdata}
            placeholder={isLoading}
            navtype={navtype}
            helptype={helptype}
          />
        ))}
        
      </ul>

      </>
    );
  }
}


