import { hasData } from "@/lib/utils/hasData"
import { useEffect, useState } from "react"
import toast, { toastConfig } from 'react-simple-toasts';

export default function ChatLikesButtonUnder({
     likes_json
    ,chatter_id
    ,my_id
    ,onClick
    ,likes
    ,chat_emoji
    ,session
    ,openModal
    ,iconSizeClass=`text-xs px-1 py-0`
    ,closeMenu=()=>{}
    ,show_number
}) {

    const [likes_json_array,set_likes_json_array] = useState(likes_json.split(','))

    const [did_i_like,set_did_i_like] = useState(likes_json_array.indexOf(my_id) > -1)
    //const [can_i_like,set_can_i_like] = useState(parseInt(chatter_id) !== parseInt(my_id))

    toastConfig({
        time: 5000,
        className: '',
        position: 'right'
    });

    //console.log("like button",likes_json,typeof likes_json,chat_id)

    useEffect(()=>{
        set_likes_json_array(likes_json.split(','))
    },[
        likes_json
    ])

    useEffect(() => {
        
            
                set_did_i_like(likes_json_array.indexOf(my_id?.toString()) > -1)
                //set_can_i_like(parseInt(chatter_id) !== parseInt(my_id))
           
      }, [
            likes_json_array
          , chatter_id
          , my_id
        ]);

    

    return (<>
            <div className="flex items-center content-center "> 
               
                <div className={`flex-0 flex items-center content-center 
                                    cursor-pointer border
                                    
                                    rounded-md overflow-hidden
                                    ${did_i_like
                                        ? "bg-gray-600 border-transparent" 
                                        : 'border-gray-600'}
                `}>
                    {show_number &&
                    <div 
                        onClick={()=>{
                           
                            //openModalLikes(chat_id)
                            openModal(likes_json,"multiple","likes",likes,"Likes From");
                            closeMenu();
                          }}
                        className={`${likes > 0 ? "flex" : "hidden"} 
                        flex-0 flex items-center content-center justify-center
                        cursor-pointer flex-0 text-xs  
                         h-6 px-1 pl-1.5
                        text-gray-400  hover:text-white
                          hover:opacity-80
                          hover:bg-gray-700

                        `}
                    >
                       
                       {likes}
                      
                    </div>
                    }
                    <div 
                        className={`flex-0 flex items-center content-center justify-center
                                    w-6 h-6 text-lg
                                     
                          hover:bg-gray-700
                        
                        `}
                        onClick={()=> {
                           
                                if (session) { 
                        
                                    set_did_i_like(!did_i_like);

                                    onClick();
                                } else {
                                    toast(`You need to login to do that!`, { time: 1000, className: '', clickable: true, clickClosable: false });
                                }
                                
                            
                        }}
                    >
                        
                       
                        {chat_emoji}
                       
                        
                        
                        
                    </div>
                </div>
        </div>
    </>)
}