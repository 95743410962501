import { MutableRefObject, useEffect, useRef } from 'react'

function useChatScrollRef<T>(dep: T,isOnScreen: boolean): MutableRefObject<HTMLDivElement> {
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    if (isOnScreen && ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [dep,isOnScreen]);
  return ref;
}

export default useChatScrollRef