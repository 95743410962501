import { hasData } from "./hasData";

export function JSONpreparrayforurl(str) {

    if (!hasData(str)) return '';

    var var_str = str;
    var_str = var_str?.replace(/\[/gi,'');
    var_str = var_str?.replace(/\]/gi,'');
    var_str = var_str?.replace(/\"/gi,'');
    var_str = var_str?.replace(/ /gi,'');

    return var_str;

}