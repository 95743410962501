
import { useEffect, useState } from "react"
import toast, { toastConfig } from 'react-simple-toasts';

export default function ChatLikesButtonAction({
     likes_json
    ,chatter_id
    ,my_id
    ,onClick
    ,likes
    ,chat_emoji
    ,session
    ,openModal
    ,iconSizeClass=`text-xs px-1 py-0`
    ,closeMenu=()=>{}
    ,show_number=false
}) {

    const [likes_json_array,set_likes_json_array] = useState(likes_json.split(','))

    const [did_i_like,set_did_i_like] = useState(likes_json_array.indexOf(my_id) > -1)
    //const [can_i_like,set_can_i_like] = useState(parseInt(chatter_id) !== parseInt(my_id))

    toastConfig({
        time: 5000,
        className: '',
        position: 'right'
    });

    //console.log("like button",likes_json,typeof likes_json,chat_id)

    useEffect(()=>{
        set_likes_json_array(likes_json.split(','))
    },[
        likes_json
    ])

    useEffect(() => {
        
            
                set_did_i_like(likes_json_array.indexOf(my_id?.toString()) > -1)
                //set_can_i_like(parseInt(chatter_id) !== parseInt(my_id))
           
      }, [
            likes_json_array
          , chatter_id
          , my_id
        ]);

    

    return (<>
                <div className={`flex items-center content-center  h-7
                                      cursor-pointer 
                                      hover:bg-gray-700
              
                                      
                                              ${did_i_like
                                                  ? "bg-gray-600" 
                                                  : ''}
                
                `}
                        onClick={()=> {
                                
                            if (session) { 
                    
                                set_did_i_like(!did_i_like);

                                onClick();
                            } else {
                                toast(`You need to login to do that!`, { time: 1000, className: '', clickable: true, clickClosable: false });
                            }
                            
                        
                    }}
                >
                    
                    <div 
                        className={`flex-0 text-2xl flex items-center content-center   ${iconSizeClass}
                                     
  
                        `}
                       
                    >
                        
                       <div className="text-lg">
                        {chat_emoji}
                       </div>
                        
                    </div>
                </div>
                    
    </>)
}