import SvgJsx from "@/components/templateux/svg/svg-jsx";
import { useEffect, useState } from "react"

export default function ChatSavesButton({
     saves_json
    ,my_id
    ,onClick
}) {

    const [saves_json_array,set_saves_json_array] = useState(saves_json.split(','))
    const [did_i_save,set_did_i_save] = useState(saves_json_array.indexOf(my_id) > -1)

        useEffect(()=>{
            set_saves_json_array(saves_json.split(','))
        },[
            saves_json
        ])

        useEffect(() => {
        
            
                set_did_i_save(saves_json_array.indexOf(my_id?.toString()) > -1)
           
      }, [
            saves_json_array
          , my_id
        ]);

    return (<> 
                 <div className={`flex-0 pl-1 cursor-pointer rounded-md w-5 mr-1
                    
                    ${did_i_save
                        ? "text-blue-600 hover:text-blue-500" 
                        : " text-gray-400  hover:text-white"}
                                     `}
                    onClick={onClick}
                > 
                    <SvgJsx 
                        type={`fill`} 
                        icon={`bookmark-sm`} 
                        className={` w-4 h-4 flex-0 my-auto `}
                        title={`Save`} 
                    />
                </div>
                              
            </>)
}