import SvgJsx from "@/components/templateux/svg/svg-jsx";
import { useEffect, useState } from "react"

export default function ChatOptionButton({
    onClick
    ,icon
    ,title
    ,display=true
}) {

  

    return (<> {display &&
                 <div className={`flex-0 pl-1 cursor-pointer rounded-md w-5 mr-1
                     text-gray-400  hover:text-white
                                     `}
                    onClick={onClick}
                > 
                    <SvgJsx 
                        type={`fill`} 
                        icon={icon} 
                        className={` w-4 h-4 flex-0 my-auto `}
                        title={title} 
                    />
                </div>
                }      
            </>)
}