// /**
//https://stackoverflow.com/questions/3971841/how-to-resize-images-proportionally-keeping-the-aspect-ratio
//   * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
//   * images to fit into a certain area.
//   *
//   * @param {Number} srcWidth width of source image
//   * @param {Number} srcHeight height of source image
//   * @param {Number} maxWidth maximum available width
//   * @param {Number} maxHeight maximum available height
//   * @return {Object} { width, height }
//   */
export function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {

    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return { width: Math.round(srcWidth*ratio), height: Math.round(srcHeight*ratio) };
 }