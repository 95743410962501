
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export const ModalChat = ({
     isOpen
    ,ModalContent
}) => {

    return (<>
                <Transition appear show={isOpen} as={Fragment}>
                    {/* <Dialog
                    as="div"
                    open={false}
                    className="fixed inset-0 overflow-y-hidden  bg-[rgb(0,0,0,0.85)]"
                    onClose={()=>{}}
                    > */}

                    <div
                        className=" fixed inset-0 overflow-y-hidden  bg-[rgb(0,0,0,0.85)]"
                    >
                    
                        <div className="min-h-screen px-[5px] text-center ">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            >
                            {/* <Dialog.Overlay className="fixed inset-0 " /> */}
                            <div 
                                className="fixed inset-0 "
                            />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            {/* <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                            >
                            &#8203;
                            </span> */}
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                            >
                            <div className="fixed top-[50px] bottom-[2px] left-[2px] right-[2px]  mx-auto inline-block  transform  overflow-hidden text-left align-middle transition-all shadow-xl rounded-md
                                            bg-gray-900  border-gray-700">
                                
                                
                                
                                
                            

                                
                                <div className=" w-full content-center item-center flex">
                            
                                    

                                    {/* CONTENT */}
                                    <div className=" flex-1">
                                    {ModalContent}
                                    </div>
                                    

                                    
                                </div>
                                

                                
                            </div>
                            </Transition.Child>

                            
                        </div>
                    </div>
                    {/* </Dialog> */}
                </Transition>
    
    
    </>)
}