import toast from "react-simple-toasts";
import { hasData } from "../hasData";

      //APPROVE LIST
      export const approveHandler = async (target_id,action,target_name,authContext,isConfirmed) => {

        const confirmed = await confirm(`Are you sure you want to ${action=="1" ? `add ${target_name} to` : `remove ${target_name} from`} the approved chatters list?`);
        //const confirmed = await isConfirmed(`Are you sure you want to ${action=="1" ? "approve" : "un-approve"} ${target_name}?`);
        // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
       if (confirmed) {
          if (hasData(target_id)) {
            const res = await fetch(`/api/private/user-approve/insert?target_id=${target_id}&action=${action}`);
            const json = await res.json()
            if (json) {

              console.log("json",json)
              
              const { outcome, approve_json } = json[0][0]

              await authContext.set_contextValue(({ 
                ...authContext
                , approve_json: approve_json
              }))

              if (outcome?.toString() == "1") { 
                toast(`${target_name} has been ${action=="1" ? "added to" : "removed from"} the approved chatters list. You can manage approved chatters in your chat settings.`, { time: 3000, className: '', clickable: true, clickClosable: false });
              } else {
                toast(`Something went wrong. Please try again.`, { time: 3000, className: '', clickable: true, clickClosable: false });
              }
            }
          }
        }
        
      }